<template>
  <div class="login-layout">
    <div class="logo">
      <van-image
        class="logoimg"
        width="30vw"
        height="30vw"
        round
        fit="contain"
        lazy-load
        :src="require('@/assets/images/logo.png')"
      />
    </div>
    <van-form class="login-form" @submit="onSubmit">
      <van-cell-group>
        <van-field
          v-model="curPerfix"
          is-link
          readonly
          left-icon="aog iconfont icon-ditu"
          @click="showPrefix = true"
        />
        <van-popup v-model:show="showPrefix" round position="bottom">
          <van-picker
            :columns="mobileperfix"
            v-model="selectedlang"
            @cancel="showPrefix = false"
            @confirm="onConfirm"
            :title="PAGELANG.请选择语言"
          />
        </van-popup>

        <van-field
          v-model="username"
          :placeholder="PAGELANG.username"
          left-icon="aog iconfont icon-mobile"
          :rules="rules.username"
        />
        <van-field
          v-model="password"
          type="password"
          :placeholder="PAGELANG.password"
          left-icon="aog iconfont icon-password"
          :rules="rules.password"
        />
      </van-cell-group>
      <!--<div class="forget">
        <a href="#">{{ PAGELANG.forget }}</a>
      </div>-->

      <van-button
        v-if="islogin"
        color="var(--van-card-price-color)"
        block
        loading
        type="primary"
        :loading-text="PAGELANG.in_login"
        size="large"
        class="btn-login"
      />
      <van-button
        v-else
        color="var(--van-card-price-color)"
        block
        type="primary"
        native-type="submit"
        size="large"
        class="btn-login"
      >
        {{ PAGELANG.login_btn }}
      </van-button>
      <div class="register">
        {{ PAGELANG.not_registered }}
        <a href="#/register">{{ PAGELANG.register }}</a>
      </div>
    </van-form>
  </div>

  <div class="login-layout-pc">
    <PCHeader />
    <div class="pc-banner">
      <div class="login-block">
        <div class="big-title">{{ PAGELANG.login_btn }}</div>
        <el-form
          :rules="rules"
          :model="logininfo"
          class="form-login"
          ref="refLogin"
          @keydown="onKeyDown"
        >
          <el-form-item>
            <i class="iconfont icon-ditu" />
            <el-select
              class="mobile-perfix"
              v-model="langcode"
              :placeholder="PAGELANG.请选择语言"
              @change="onChangeLang"
            >
              <template v-for="(item, index) in mobileperfix" :key="index">
                <el-option :label="item.text" :value="item.value" />
              </template>
            </el-select>
          </el-form-item>

          <el-form-item prop="username" :inline-message="true">
            <i class="iconfont icon-mobile" />
            <el-input
              v-model="logininfo.username"
              :placeholder="PAGELANG.username"
            />
          </el-form-item>

          <el-form-item prop="password" :inline-message="true">
            <i class="iconfont icon-password" />
            <el-input
              v-model="logininfo.password"
              type="password"
              autocomplete="off"
              :placeholder="PAGELANG.password"
            />
          </el-form-item>

          <el-form-item style="border-bottom: 0; height: auto">
            <el-button
              v-if="islogin"
              class="btn-login"
              type="primary"
              color="#000000"
              loading
              >{{ PAGELANG.in_login }}</el-button
            >
            <el-button
              v-else
              class="btn-login"
              type="primary"
              color="#000000"
              :auto-insert-space="true"
              @click="onLogin"
              >{{ PAGELANG.login_btn }}</el-button
            >
          </el-form-item>
        </el-form>
      </div>
    </div>
    <PCFooter />
  </div>
</template>

<style>
.van-icon-aog {
  color: #cfaf6d;
}
</style>

<style scoped>
@media (max-width: 768px) {
  .login-layout-pc {
    display: none;
  }
  .login-layout {
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    width: 100vw;
    height: 100vh;
  }

  .login-layout .logo {
    position: relative;
    border-radius: 50%;
  }

  .login-layout .logo::before,
  .login-layout .logo::after {
    content: "";
    display: block;
    position: absolute;
    width: calc(100% - 4px);
    height: calc(100% - 4px);
    border-radius: inherit;
  }

  .login-layout .logo::before {
    top: 1px;
    left: 1px;
    z-index: 10;
    background-color: #fff;
  }

  .login-layout .logo::after {
    top: 0;
    left: 0;
    z-index: 1;
    padding: 1px;
    background: linear-gradient(to right, #fff, #d5ba80);
  }

  .login-layout .logo .logoimg {
    z-index: 100;
  }

  .login-form {
    width: 90vw;
    margin-top: 9vw;
    --van-cell-horizontal-padding: 0;
    --van-padding-md: 0;
  }

  .forget {
    line-height: 38px;
    font-size: 12px;
    text-align: right;
  }

  .forget > a {
    color: #143949;
  }

  .btn-login {
    margin-top: 40px;
  }

  .register {
    text-align: center;
    padding-top: 20px;
    line-height: 44px;
    font-size: 12px;
    color: #979797;
  }

  .register > a {
    font-size: 14px;
    color: #143949;
    margin-left: 10px;
  }
}
</style>
<style src="../assets/css/login.css" scoped></style>

<script>
import PCHeader from "../components/PCHeader.vue";
import PCFooter from "../components/PCFooter.vue";
import { getCurrentInstance } from "vue";
import { closeToast, showToast, showLoadingToast } from "vant";
import { getCookie, setCookie } from "../util";
//import { setCookie } from "../util/index.js";
export default {
  components: {
    PCHeader,
    PCFooter,
  },
  data() {
    const _this = getCurrentInstance();
    const gp = _this.appContext.config.globalProperties;

    return {
      PAGELAN: {},
      mobileperfix: [],
      curPerfix: "",
      selectedlang: [""],
      username: "",
      password: "",
      showPrefix: false,
      islogin: false,
      globalprops: gp,
      logininfo: { username: "", password: "" },
      rules: {
        username: [
          { required: true, message: this.PAGELANG.username, trigger: "blur" },
        ],
        password: [
          { required: true, message: this.PAGELANG.password, trigger: "blur" },
        ],
      },
    };
  },
  beforeCreate() {
    console.log(this);
    this.PAGELANG = this.LANG.pages.login;
    this.SYSLANG = this.LANG.system;

    this.langcode = getCookie("lh_aog_langcode");
  },
  methods: {
    onConfirm({ selectedOptions }) {
      console.log(selectedOptions);
      this.showPrefix = false;
      this.curPerfix = selectedOptions[0].text;
      setCookie("lh_aog_langcode", selectedOptions[0].value);
      location.reload();
    },

    onChangeLang(val) {
      this.langcode = val;
      setCookie("lh_aog_langcode", val);
      location.reload();
    },

    onLogin() {
      this.$refs.refLogin.validate((valid) => {
        console.log(valid);
        if (valid) {
          this.username = this.logininfo.username;
          this.password = this.logininfo.password;
          this.onSubmit();
        } else {
          return false;
        }
      });
    },

    onSubmit() {
      let postobj = {};

      postobj.username = this.username;
      postobj.password = this.password;

      console.log(postobj);

      showLoadingToast({
        message: this.SYSLANG.loading,
        duration: 0,
        forbidClick: true,
      });

      this.islogin = true;

      this.axios.post(this.actions.dologin, postobj).then((response) => {
        this.islogin = false;
        //登录结果
        console.log(response);
        let retdata = response.data;

        closeToast();

        if (retdata.retcode == "OK") {
          //保存好agentid和logintoken
          setCookie("agentloginid", retdata.agentid, 30);
          setCookie("agentlogintoken", retdata.logintoken, 30);

          if (retdata.status == 1) {
            //直接进入首页
            this.$router.push("/");
          } else if (
            retdata.status == 0 &&
            retdata.curstep > 0 &&
            retdata.curstep < 7
          ) {
            this.$router.push("/appflow");
          }
        } else {
          showToast(this.PAGELANG[retdata.retinfo]);
        }
      });
    },

    onKeyDown(e) {
      if (e.keyCode == 13) {
        this.onLogin();
      }
    },
  },

  created() {
    this.axios.get(this.actions.mobile_prefix).then((response) => {
      console.log(response, this.langcode);
      this.mobileperfix = response.data;
      let langitem = this.mobileperfix.find((item) => {
        if (item.value == this.langcode) return true;
        return false;
      });

      if (langitem) {
        this.selectedlang = [langitem.value];
        this.curPerfix = langitem.text;
      }

      // if(langitem)
      //   this.selectedlang =
      // // this.curPerfix = response.data[0].text;
    });
  },
};
</script>
